import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import EndpointPaths from "~/config/constants/EndpointPaths";
import type ContactEmailDto from "~/models/ContactEmailDto";
import BaseService from "~/services/BaseService";

export class ContactEmailProvider {
  private static _instance: ContactEmailProvider;

  static get instance(): ContactEmailProvider {
    if (ContactEmailProvider._instance === undefined)
      ContactEmailProvider._instance = new ContactEmailProvider();

    return ContactEmailProvider._instance;
  }

  /**
   *
   * @param dto DTO with the content of the email
   * @returns 204 on success
   */
  public async sendEmail(dto: ContactEmailDto): Promise<AnybillResult<undefined>> {
    return BaseService.Instance.post<undefined>(
      {
        path: `${EndpointPaths.SEND_EMAIL}`,
        body: dto.toDto(),
        isComponentFetch: true,
        authNeeded: false,
      },
    );
  }
}
