<script setup lang="ts">
import { useLocale } from "vuetify";

const properties = defineProps({
  showFullName: Boolean,
});
const emit = defineEmits(["change"]);
const { current } = useLocale();
const { locale, setLocale } = useI18n();

let languages = [
  {
    name: "Deutsch",
    shortName: "de",
    icon: "/icons/flags/flag-german.svg",
  },
  {
    name: "English",
    shortName: "en",
    icon: "/icons/flags/flag-english.svg",
  },
];

const fullName = computed(() => {
  const l = languages.filter((langObj) => {
    return langObj.shortName === locale.value;
  })[0];
  return l.name;
});

const languageActivator = computed(() => ({
  title: properties.showFullName ? fullName.value : useUpperCase(locale.value),
  icon: "globe",
}));

onMounted(() => {
  const sortedArr = languages.reduce((acc: any, element: any) => {
    if (element.shortName === locale.value) { return [element, ...acc]; }

    return [...acc, element];
  }, []);

  languages = sortedArr;
});

function isActive(language: any) {
  return language.shortName === locale.value;
}

function queryString() {
  const hasQueries = Object.keys(useRoute().query).length !== 0;
  if (hasQueries) {
    const q = useRoute().query;
    return (
      `?${
      Object.keys(q)
        .map((key) => {
          const val = q[key] as string;
          return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
        })
        .join("&")}`
    );
  }
  return "";
}

function switchToLang(language: any) {
  setLocale(language.shortName);

  current.value = language.shortName;

  emit("change");
}
</script>

<template>
  <v-menu
    offset-y
    :close-on-content-click="true"
    content-class="languageSwitcher"
  >
    <template #activator="{ props }">
      <!-- for navbar -->
      <v-btn
        v-if="!showFullName"
        v-bind="props"
        variant="text"
        class="label-large ml-2 mr-n5"
      >
        <GlobeIcon class="mr-2" />
        {{ $i18n.locale.toUpperCase() }}
      </v-btn>
      <!-- for navigation drawer -->
      <navigation-item
        v-if="showFullName"
        :item="languageActivator"
        v-bind="props"
      />
    </template>

    <v-list>
      <v-list-item
        v-for="(language, index) in languages"
        :key="index"
        style="cursor: pointer"
      >
        <v-list-item-title @click="switchToLang(language)">
          <div class="d-flex">
            <div>
              <img
                :src="language.icon"
                :alt="language.shortName"
                width="40px"
              >
            </div>
            <div
              class="title-medium align-self-center"
              :style="
                language.shortName === locale
                  ? 'color:#000'
                  : 'color:#74777e'
              "
            >
              {{ language.name }}
            </div>
          </div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style lang="scss" scoped>
.languageSwitcher {
  min-width: auto !important;
}
</style>
