<script setup lang="ts">
import ContactEmailDto from "~/models/ContactEmailDto";
import AnybillCheckbox from "~/components/AnybillCheckbox.vue";
import { useRules } from "~/composables/useValidationRules";
import type { AnybillResult } from "~/additionalModels/AnybillResponse";
import { ContactEmailProvider } from "~/provider/ContactEmailProvider";

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  showActivator: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:open"]);

const { required, valEmail } = useRules();

const snackbarModule = useSnackBarModule();
const { t } = useI18n();

const syncedOpen = computed({
  get: () => props.open,
  set: val => emit("update:open", val),
});

const message = ref<ContactEmailDto>(new ContactEmailDto());
const isOpen = ref(false);
const acceptTerms = ref(false);
const isLoading = ref(false);
const firstName = ref("");
const lastName = ref("");

const form = ref<HTMLFormElement>();

watch(syncedOpen, (change) => {
  isOpen.value = change;
});

async function sendMessage() {
  if (!(await form.value?.validate() as any).valid) {
    return;
  }

  // Do not send when in dev mode
  if (process.env.NODE_ENV === "development") {
    snackbarModule.showError({
      text: "DEV: not allowed",
    });
    return;
  }

  isLoading.value = true;
  message.value.name = `${firstName.value} ${lastName.value}`;

  try {
    const test = message.value;
    const response = await ContactEmailProvider.instance.sendEmail(test);

    if (response.success) {
      isLoading.value = false;
      snackbarModule.showSuccess({
        text: t("components.contactForm.successMessage"),
      });
      close();
    }
    else {
      snackbarModule.showError({
        text: t("components.contactForm.failedMessage"),
      });
    }
  }
  catch (error) {
    isLoading.value = false;
    snackbarModule.showError({
      text: t("components.contactForm.failedMessage"),
    });
  }
}

function close() {
  syncedOpen.value = false;
  resetMessage();
}

function resetMessage() {
  message.value = new ContactEmailDto();
  acceptTerms.value = false;
  if (form.value) { (form.value as HTMLFormElement).reset(); }
}
</script>

<template>
  <BaseDialog
    id="contact-form-dialog"
    v-model:open="isOpen"
    :on-save="sendMessage"
    width="auto"
    :on-cancel="close"
    :cta-label="$t('components.contactForm.sendBtn')"
  >
    <template
      v-if="showActivator"
      #activator
    >
      <AnybillButton
        @click="isOpen = true"
      >
        {{ $t('components.contactForm.title') }}
      </AnybillButton>
    </template>

    <template #title>
      {{ $t("components.contactForm.title") }}
    </template>

    <template #content>
      <v-form
        ref="form"
        validate-on="submit"
      >
        <v-row>
          <v-col>
            <AnybillTextField
              id="text-field-firstName"
              v-model="firstName"
              placeholder="Vorname"
              :rules="[required]"
            >
              <template #label>
                <span class="red--text">*</span>{{ $t('components.contactForm.firstName') }}
              </template>
            </AnybillTextField>
          </v-col>
          <v-col>
            <AnybillTextField
              id="text-field-lastName"
              v-model="lastName"
              :rules="[required]"
            >
              <template #label>
                <span class="red--text">*</span>{{ $t('components.contactForm.lastName') }}
              </template>
            </AnybillTextField>
          </v-col>
        </v-row>

        <AnybillTextField
          id="text-field-email"
          v-model="message.email"
          :rules="[required, valEmail]"
          :placeholder="$t('components.contactForm.email')"
          validate-on-blur
        >
          <template #label>
            <span class="red--text">*</span>{{ $t('components.contactForm.email') }}
          </template>
        </AnybillTextField>

        <AnybillTextarea
          id="text-area-message"
          v-model="message.text"
          :rules="[required]"
          rows="3"
        >
          <template #label>
            <span class="red--text">*</span>{{ $t("components.contactForm.message") }}
          </template>
        </AnybillTextarea>

        <span style="font-size: 12px;">{{
          $t("components.contactForm.requiredField")
        }}</span>

        <AnybillCheckbox
          id="checkbox-data-privacy"
          v-model:checkValue="acceptTerms"
          :rules="[required]"
        >
          <template #label>
            <div class="tw-text-xs">
              {{ $t("components.contactForm.Iaccept") }}
              <a
                :href="$i18n.locale === 'en'
                  ? 'https://anybill.de/en/privacyWeb'
                  : 'https://anybill.de/privacyWeb'
                "
                target="_blank"
                style="color: black"
                @click.stop
              >{{ $t("components.contactForm.privacy") }}</a>
              {{ $t("components.contactForm.privacyTwo") }}
            </div>
          </template>
        </AnybillCheckbox>
      </v-form>
    </template>
  </BaseDialog>
</template>

<style scoped>
.contact-option {
  height: 43px;
  border-bottom: solid;
  border-bottom-width: thin;
}
</style>
