import DtoObject from "~/models/DtoObject";

export default class ContactEmailDto extends DtoObject<ContactEmailDto> {
  text: string = "";
  email: string = "";
  name: string | null = null;

  constructor(contactEmailDtoPart: Partial<ContactEmailDto> = {}) {
    super();
    Object.assign(this, contactEmailDtoPart);
  }

  toJSON() {
    return {
      text: this.text,
      email: this.email,
      name: this.name,
    };
  }

  static testValuesFactoryAllNull() {
    return new ContactEmailDto({
      text: "WTpJIdhweilKJlhBEAdaMTcxnZBSDIBO",
      email: "VNglObBouzsdYzwxtbsdLIGqQHLmcnAx",
      name: null,
    });
  }

  static testValuesFactorySomeNull() {
    return new ContactEmailDto({
      text: "NWmTHMiRdaVHOLZQPLIVIukdkhoOwxGG",
      email: "TDqsngVSNSpriWVmwZTWIiRjMeSYBvhH",
      name: null,
    });
  }

  static testValuesFactoryNoneNull() {
    return new ContactEmailDto({
      text: "oFukehvDelaNhsqNkMgIlrrExtyDbUnu",
      email: "HsRBIKxwLMsWqiWPPEJeuDYcNZpRXFNV",
      name: "cTfLggcwEWGboJGtIuwwVdPGtbBspzlp",
    });
  }
}
