<script setup lang="ts">
const props = defineProps({
  item: { type: Object as () => any },
  icon: { type: String, default: "" },
});

const emit = defineEmits(["handleButtonClick"]);

const localePath = useLocalePath();

function handleButtonClick() {
  emit("handleButtonClick");
}
</script>

<template>
  <div v-if="!item.hidden">
    <!-- nuxt link -->
    <v-list-item
      v-if="item.link"
      nuxt
      :disabled="item.disabled"
      :to="localePath(item.link)"
      class="pl-5"
      :ripple="false"
      variant="plain"
    >
      <v-list-item-title
        class="title-medium"
        :class="item.hasMarker ? 'marker' : ''"
      >
        <v-row
          no-gutters
          align="center"
        >
          <svg-icon
            v-if="item.icon"
            width="16px"
            height="16px"
            class="ml-3 mr-2"
            :name="item.icon"
          />
          <span
            class="text-truncate"
            style="max-width: 170px"
          >{{
            $t(item.title)
          }}</span>
          <div
            v-if="item.notif"
            class="notif-wobble"
          />
        </v-row>
      </v-list-item-title>
    </v-list-item>

    <!-- external link -->
    <v-list-item
      v-if="item.href"
      :href="item.href"
      target="_blank"
      class="pl-5"
      :ripple="false"
      variant="plain"
    >
      <v-list-item-title class="title-medium">
        <v-row
          no-gutters
          align="center"
        >
          <svg-icon
            v-if="item.icon"
            width="16px"
            height="16px"
            class="ml-3 mr-2"
            :name="item.icon"
          />
          <span
            class="text-truncate"
            style="max-width: 10rem"
          >{{
            $t(item.title)
          }}</span>
          <div
            v-if="item.notif"
            class="notif-wobble"
          />
        </v-row>
      </v-list-item-title>
    </v-list-item>

    <!-- button -->
    <v-list-item
      v-if="!item.href && !item.link"
      target="_blank"
      class="pl-5"
      :ripple="false"
      variant="plain"
      @click="handleButtonClick"
    >
      <v-list-item-title class="title-medium">
        <v-row
          no-gutters
          align="center"
        >
          <svg-icon
            v-if="item.icon"
            width="16px"
            height="16px"
            class="ml-3 mr-2"
            :name="item.icon"
          />
          <div
            class="text-truncate"
            style="width: 160px"
          >
            {{
              $t(item.title)
            }}
          </div>
        </v-row>
      </v-list-item-title>
    </v-list-item>
  </div>
</template>

<style lang="scss" scoped></style>
